import {
  AnalyticsEventsType,
  BLOCKED_POSTHOG_EVENTS,
} from '@/config/analytics-events/analytics-events';
import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from '@/config/global-constants';
import { Me } from '@/types/me';
import { posthog } from 'posthog-js';
import { BookingOrderAnalyticsMetadata, GA4SessionInfo } from './types';

export const baseTrackEvent = (
  eventName: AnalyticsEventsType,
  eventDetails?: any,
) =>
  new Promise<void>((resolve) => {
    if (!BLOCKED_POSTHOG_EVENTS.includes(eventName)) {
      posthog.capture(eventName, eventDetails);
    }
    resolve();
  });

export const baseTrackPage = (pageName: string, eventDetails?: any) => {
  posthog.capture('$pageview', { $current_url: pageName, ...eventDetails });
};

export const identify = (user: Me) => {
  posthog.identify(user.uuid);

  if (window.braze) {
    window.braze.changeUser(posthog.get_distinct_id());
  }
};

export const getAnonymousId = () => {
  // Replace with PostHog distinct_id
  return posthog.get_distinct_id();
};

const getGtagValue = (
  field: string,
  timeoutMs = 5000,
): Promise<string | null> => {
  return new Promise<string | null>((resolve) => {
    const timer = setTimeout(() => {
      console.warn('gtag is not responding');
      resolve(null);
    }, timeoutMs);

    if (typeof window.gtag === 'function') {
      try {
        window.gtag(
          'get',
          GOOGLE_ANALYTICS_MEASUREMENT_ID,
          field,
          (value: string | null) => {
            clearTimeout(timer);
            resolve(value);
          },
        );
      } catch (error) {
        clearTimeout(timer);
        resolve(null);
      }
    } else {
      clearTimeout(timer);
      resolve(null);
    }
  });
};

const getGA4SessionInfo = async (): Promise<GA4SessionInfo | null> => {
  if (typeof window.gtag !== 'function' || !GOOGLE_ANALYTICS_MEASUREMENT_ID) {
    return null;
  }

  const [sessionId, sessionNumber, clientId] = await Promise.all([
    getGtagValue('session_id'),
    getGtagValue('session_number'),
    getGtagValue('client_id'),
  ]);

  if (!sessionId && !sessionNumber && !clientId) {
    return null;
  }
  return {
    client_id: clientId ?? '',
    session_id: sessionId ?? '',
    session_number: sessionNumber?.toString() ?? '',
  };
};

export const getBookingOrderAnalyticsMetadata = async (): Promise<
  BookingOrderAnalyticsMetadata | undefined
> => {
  const googleAnalyticsSessionInfo = await getGA4SessionInfo();

  if (!googleAnalyticsSessionInfo) {
    return undefined;
  }

  return {
    ga_session_info: googleAnalyticsSessionInfo,
  };
};
