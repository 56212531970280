import { UseQueryOptions, useQuery } from 'react-query';
import DcsFlags from '@/types/dcs-flags';
import request, { ApiResponse, RequestError } from '@/utils/request';
import {
  CACHE_DURATION_MS,
  STALE_DURATION_MS,
} from '@/constants/requests-configuration';

type GenericParams = Record<string, unknown>;

type FlagRequest<ParamsType = GenericParams> = {
  flag: DcsFlags;
  options?: UseQueryOptions<any, RequestError>;
  params?: ParamsType;
};

const fetchDcsFlag = async <ResponseType, RequestType = GenericParams>({
  flag,
  params,
}: FlagRequest<RequestType>) => {
  const { data: response } = await request<ApiResponse<ResponseType>>({
    url: `/v1/dcs/public/${flag}`,
    params,
  });

  return response.data;
};

const useDcsFlag = <ResponseType, RequestType = GenericParams>(
  flagRequest: FlagRequest<RequestType>,
) => {
  const query = useQuery<ResponseType, RequestError>(
    [`dcs-flag-${flagRequest.flag}`, flagRequest],
    () => fetchDcsFlag<ResponseType, RequestType>(flagRequest),
    {
      cacheTime: CACHE_DURATION_MS.small,
      staleTime: STALE_DURATION_MS.small,
      retry: 1,
      ...flagRequest.options,
    },
  );

  return {
    ...query,
    isFlagEnabled: !!query?.data,
  };
};

export default useDcsFlag;
