export const IS_BROWSER = typeof window !== 'undefined';
export const IS_SERVER = typeof window === 'undefined';
// TODO: Find the error with the server side rendering and revert this
// export const IS_PRODUCTION = process.env.STAGE === 'production';
export const IS_PRODUCTION = process.env.NEXT_PUBLIC_STAGE === 'production';
export const NEXT_PUBLIC_STAGE = process.env.NEXT_PUBLIC_STAGE as string;

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL as string;

export const BOOKING_SOURCE_MOBILE = 'web_mobile';
export const BOOKING_SOURCE_DESKTOP = 'web_desktop';

export const APPLE_SIGN_IN_CLIENT_ID = process.env
  .NEXT_PUBLIC_APPLE_SIGN_IN_CLIENT_ID as string;

export const APPLE_SIGN_IN_REDIRECT_URI = process.env
  .NEXT_PUBLIC_APPLE_SIGN_IN_REDIRECT_URI as string;

export const APPLE_SIGN_IN_SCOPES = 'name email';

export const HIDE_ONBOARDING_POPUP_FOR_UTM_SOURCE = ['braze'];

export const SEARCH_CONFIG_MAPPER: {
  [key: string]: {
    showSearchInput: boolean;
    showSearchToggler: boolean;
  };
} = {
  '/': {
    showSearchInput: true,
    showSearchToggler: false,
  },
  '/car-picker': {
    showSearchInput: false,
    showSearchToggler: true,
  },
  '/trip-details/[id]': {
    showSearchInput: false,
    showSearchToggler: false,
  },
  default: {
    showSearchInput: false,
    showSearchToggler: false,
  },
};

export const REACT_QUERY_RETRY_LIMIT = 3;

export const SUPPORT_TIMEZONE =
  process.env.NEXT_PUBLIC_SUPPORT_TIMEZONE || 'America/Los_Angeles';

export const SUPPORT_START_HOUR =
  parseInt(process.env.NEXT_PUBLIC_SUPPORT_START_HOUR || '', 10) || 3;

export const SUPPORT_END_HOUR =
  parseInt(process.env.NEXT_PUBLIC_SUPPORT_END_HOUR || '', 10) || 22;

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export const COMMIT_SHA = process.env.NEXT_PUBLIC_COMMIT_SHA;

export const ANALYTICS_ANONYMOUS_ID_COOKIE = 'ajs_anonymous_id';
export const ANALYTICS_USER_ID_COOKIE = 'ajs_user_id';
export const IS_LOGGED_IN_COOKIE = 'is_logged_in';
export const IS_LOGGED_IN_COOKIE_MAX_AGE = 3600;
export const EXPERIMENT_COOKIE = 'exp_data';

export enum HttpStatus {
  BAD_REQUEST = 400,
}

export const STRAPI_API_URL =
  process.env.NEXT_PUBLIC_STRAPI_API_URL ||
  'https://agw.kyte-internal.com/http/strapi';

export const STRAPI_API_KEY =
  process.env.NEXT_PUBLIC_STRAPI_KEY ||
  'a7128072cd602ccbd7a305b15dd078b567feb38dfb68ff860fc82c184871705476a46b014141219c56f81176e2cea9cb6d18d43ff16e6252c09ad0fb9e3df95be6e35a09720aaac4739984b1500702315ac94b22d73bb6f0082f2d8af4fabaf8fdd64d1c430f488040ad80b208820094272c1f0c16329937ba1fb698cb94831c';

export const STRAPI_AGW_AUTH_SECRET = process.env
  .STRAPI_AGW_AUTH_SECRET as string;

export const POSTHOG_API_KEY = process.env
  .NEXT_PUBLIC_POSTHOG_API_KEY as string;

export const POSTHOG_API_HOST = 'https://p.kyte.com';

export const FAQ_URL = 'https://help.kyte.com/en';

export const NEW_SMS_REQUEST_TIMEOUT = 60 * 1000;

export const GOOGLE_MAPS_BASE_URL = 'https://www.google.com/maps/search/?api=1';

export const REQUEST_TIMEOUT_MS = 60000;

export const VERIFICATION_REQUIREMENT_FOR_RENTING =
  'https://link.kyte.com/1HEFCZ';

export const COOKIE_YES = 'cookieyes-consent';

export const DEFAULT_REMAINING_HOURS_FOR_FULL_REFUND = 48;
export const DEFAULT_REMAINING_HOURS_FOR_FREE_REFUND = 2;

export const KYTE_PRIVACY_POLICY =
  'https://drivekyte.s3-us-west-2.amazonaws.com/kyteweb-assets/docs/KYTE_privacypolicy.pdf';
export const KYTE_TERMS_OF_SERVICE =
  'https://drivekyte.s3-us-west-2.amazonaws.com/kyteweb-assets/docs/KYTE_TermsOfService.pdf';

export const ALLLOWED_LOGOUT_URLS =
  'http://localhost:3000, http://localhost:3000/car-picker, http://localhost:3000/checkout, http://localhost:3000/subscription/checkout, https://*.drivekyte.com, https://*.kyte.com, https://*.kyte.com/car-picker, https://*.kyte.com/checkout, https://*.kyte.com/car-subscription, https://*.kyte.com/subscription/car-picker, https://*.kyte.com/subscription/checkout, https://*.kyte.com/landing-page-v2, https://drivekyte.vercel.app';

export const KYTE_APP_DOWNLOAD_LINK = 'https://link.kyte.com/TpMAnf';
export const KYTE_APP_DOWNLOAD_LINK_IOS = 'https://link.kyte.com/X4gWZx';
export const KYTE_APP_DOWNLOAD_LINK_ANDROID = 'https://link.kyte.com/3KZdzQ';

export const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env
  .NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID as string;
